.loading img {
  width: 160px;
  display: block;
  filter: drop-shadow(0 0 0.75rem #282c34);
  animation: App-logo-zoom infinite 0.7s alternate;
}

.loading figcaption {
  padding-top: 2em;
  text-align: center;
}

@keyframes App-logo-zoom {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.2);
  }
}
