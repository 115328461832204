/* Custom styles */

/* Remove arrow for input number */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Add scroll on modal */

.lunatic-modal {
  overflow-y: auto;
}
