html,
body {
  margin: 0;
}
#root {
  height: 100vh;
}

/* Custom styles */

#main {
  padding: 10px;
}

#main.lunatic-component {
  margin: 20px;
}

#main .lunatic-component .lunatic-modal > .lunatic-modal-content {
  top: 100px;
}

/* Boutons radio */
#main .radio-modality.radio-modality-block {
  padding: 0px 10px 0px 0px;
  border-radius: 5px;
  height: unset !important;
}

#main
  .radio-modality.radio-modality-block
  > .list-icon-wrapper
  > .list-icon
  > svg {
  fill: #2b2b2b;
}
#main
  .radio-modality.radio-modality-block
  > .list-icon-wrapper
  > .modality-label,
#main .radio-modality.radio-modality-block {
  color: #2b2b2b;
  font-size: 10pt;
  font-family: 'Open Sans', sans-serif;
}

#main
  .radio-modality.radio-modality-block.content-checked
  > .list-icon-wrapper
  > .list-icon
  > svg {
  fill: #154783;
}

#main
  .radio-modality.radio-modality-block:focus-within
  > .list-icon-wrapper
  > .list-icon
  > svg {
  fill: #154783;
}
.lunatic-combo-box-container.default-style
  .lunatic-combo-box
  .lunatic-combo-box-content
  .lunatic-combo-box-panel.expanded {
  height: 50vh;
  overflow-y: auto;
}
.lunatic-component.outerContainer-Sequence .label-description p {
  color: #0f417a !important;
  margin: 20px !important;
  font-style: normal;
  font-family: 'Roboto', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 12px;
  line-height: 10.7px;
}

#main
  .radio-modality.radio-modality-block.content-checked
  > .list-icon-wrapper
  > .modality-label,
#main .radio-modality.radio-modality-block.content-checked {
  background: #b4d6ff;
  color: #154783;
}

#main .radio-modality.radio-modality-block.content-checked {
  border: 1px solid #154783;
}
#main .list-icon-wrapper > .modality-label,
#main .radio-modality.radio-modality-block {
}

#main label.modality-label,
#main input.radio-lunatic {
  cursor: pointer;
}

#main .radio-modality > .list-icon-wrapper > .list-icon {
  margin-top: auto;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
}

#main .radio-modality > .list-icon-wrapper > input {
  margin-top: auto;
  height: 100%;
  top: 0;
}

#main .radio-modality > .list-icon-wrapper > .list-icon > svg {
  vertical-align: middle;
}

#main .radio-modality > .list-icon-wrapper > .list-icon * {
  vertical-align: middle;
}

#main .radio-modality > .list-icon-wrapper > label {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px !important;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#main .radio-modality {
  position: relative;
}

#main .radio-group-list {
  margin-top: 0;
}
#main .lunatic-component label {
  color: #2b2b2b;
  font-size: 13px;
}
#main .lunatic-component legend,
#main .lunatic-component .subsequence-lunatic,
#main .lunatic-component .lunatic-dropdown-label,
#main .lunatic-component .lunatic-dropdown-label focused #main {
  color: black;
  font-size: 13px;
  line-height: 16px;
}

/* Multiple choice buttons */
#main .checkbox-modality {
  padding: 0px 10px;
  border-radius: 5px;
  height: unset !important;
}

#main .checkbox-modality > .list-icon-wrapper > .list-icon > svg {
  fill: #2b2b2b;
}
#main .checkbox-modality > .list-icon-wrapper > .modality-label,
#main .checkbox-modality {
  color: #2b2b2b;
}

#main
  .checkbox-modality.content-checked
  > .list-icon-wrapper
  > .list-icon
  > svg {
  fill: #154783;
}

#main .checkbox-modality:focus-within > .list-icon-wrapper > .list-icon > svg {
  fill: #154783;
}

#main .checkbox-modality.content-checked > .list-icon-wrapper > .modality-label,
#main .checkbox-modality.content-checked {
  background: #b4d6ff;
  color: #154783;
}
#main .checkbox-modality.content-checked {
  border: 1px solid #154783;
}

#main .list-icon-wrapper > .modality-label,
#main .checkbox-modality {
}

#main label.modality-label,
input.checkbox-lunatic {
  cursor: pointer;
}

#main .checkbox-modality > .list-icon-wrapper > .list-icon {
  margin-top: auto;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
}

#main .checkbox-modality > .list-icon-wrapper > input {
  margin-top: auto;
  height: 100%;
  top: 0;
}

#main .checkbox-modality > .list-icon-wrapper > .list-icon > svg {
  vertical-align: middle;
}

#main .checkbox-modality > .list-icon-wrapper > .list-icon * {
  vertical-align: middle;
}

#main .checkbox-modality > .list-icon-wrapper > label {
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 10px !important;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

#main .checkbox-modality {
  position: relative;
}

#main .checkbox-group-list {
  width: fit-content;
  width: -moz-fit-content;
}

#main .checkbox-group-list .field {
  display: block;
}

/* Date */
#main input[type='date'] {
  border: 1px solid #b9b9b9;
  padding: 2px 8px !important;
  border-bottom: 0.125rem solid #b9b9b9;
  border-radius: 8px;
  padding: 0.375rem 0.4375rem 0.375rem 0.4375rem;
  height: 20px;
}

#main .datepicker-lunatic:hover,
#main .datepicker-lunatic:focus {
  border-bottom: 0.125rem solid #b9b9b9;
}

#main input::-webkit-datetime-edit {
  width: 50px;
}

/* Question labels */

#main .label-top > label {
  font-size: 10pt;
  color: black;
  font-family: 'Open Sans', sans-serif;
  line-height: 16px;
  font-weight: bold;
}

.checkbox-group-list > legend,
#main .checkbox-group-list > legend,
.checkbox-boolean-modality > label,
#main .lunatic-dropdown-label,
.lunatic-component > label,
#main .radio-group-list > legend {
  color: black;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 13px;
  line-height: 16px;
}

#main .declaration-lunatic {
  font-style: normal;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-size: 12px;
  line-height: 10.7px;
  margin-bottom: 5px;
}

#main .lunatic-component .declarations-lunatic .declaration-lunatic {
  color: black;
}

#main
  .outerContainer-Subsequence
  > .declarations-lunatic
  > .declaration-lunatic,
#main .outerContainer-Sequence > .declarations-lunatic > .declaration-lunatic {
  color: #0f417a;
}

/* Question responses */
#main .field > textarea,
.field > input,
#main span.unit {
  font-family: 'Open Sans', sans-serif;
  font-size: 10pt;
}

#main .field > textarea,
#main .field > input {
  border: 1px solid #b9b9b9;
  border-radius: 8px;
  border-bottom: 0.125rem solid #b9b9b9;
  padding-right: 5px;
  padding-right: 5px;
  margin-bottom: 5px;
  margin-top: 5px;
}

#main .field > textarea:hover,
#main .field > input:hover,
#main .field > textarea:focus,
#main .field > input:focus {
  border-bottom: 0.125rem solid #b9b9b9;
}

#main span.unit {
  margin-left: 8px !important;
}

/* #main div.field-container,
#main div.declarations-lunatic {
} */
#main .field-container .field .lunatic-textarea .label-description.INSTRUCTION p,
#main .field-container .field .lunatic-textarea .label-description.HELP p {
  color: black !important;
}

/* Sequences / Subsequences */
#main .sequence-lunatic {
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #0d3970;
  padding: 0.2em;
  font-size: 19px;
  height: unset;
}

#main .subsequence-lunatic {
  width: inherit;
  font-weight: bold;
  font-style: normal;
  font-size: 10pt;
  height: unset;
  color: white;
  padding-left: 25px;
  background-color: #3467ae;
  padding: 0.2em;
}
#main .outerContainer-Subsequence {
  border: 1px solid #3467ae;
  background: #9fc0e5;
}
#main .outerContainer-Sequence {
  border: 1px solid #0d3970;
  background: #e4f0fc;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
}

#main .subElementsInnerContainer-Subsequence,
#main .subElementsInnerContainer-Sequence {
  padding: 8px 15px;
}

#main .outerContainer-Subsequence > .declarations-lunatic,
#main .outerContainer-Sequence > .declarations-lunatic {
  margin: 0;
}

#main
  .outerContainer-Subsequence
  > .declarations-lunatic
  > .declaration-lunatic,
#main .outerContainer-Sequence > .declarations-lunatic > .declaration-lunatic {
  margin: 20px !important;
  color: #0f417a;
}

#main .declarations-lunatic {
  margin: 0;
}
#main .declaration-lunatic {
  margin-bottom: 5px;
}

#main .lunatic.lunatic-component.Subsequence {
  box-shadow: none;
  padding: 0;
  margin-top: 30px;
}

#main .lunatic-component.outerContainer-Sequence > .declarations-lunatic,
#main .lunatic-component.outerContainer-Subsequence > .declarations-lunatic {
  padding-left: 30px;
}

/* Dropdown list */

#main .lunatic-dropdown-option {
  font-size: 10pt;
}
