.notFound > *,
.notFound > *:before,
.notFound > *:after {
  box-sizing: content-box;
  transform: translate3d(0, 0, 0);
}
.face {
  width: 300px;
  height: 300px;
  border: 4px solid #383a41;
  border-radius: 10px;
  background-color: #fff;
  margin: 0 auto;
  margin-top: 100px;
}
@media screen and (max-width: 400px) {
  .face {
    margin-top: 40px;
    transform: scale(0.8);
  }
}
.face .band {
  width: 350px;
  height: 27px;
  border: 4px solid #383a41;
  border-radius: 5px;
  margin-left: -25px;
  margin-top: 50px;
}
.face .band .red {
  height: calc(100% / 3);
  width: 100%;
  background-color: #eb6d6d;
}
.face .band .white {
  height: calc(100% / 3);
  width: 100%;
  background-color: #fff;
}
.face .band .blue {
  height: calc(100% / 3);
  width: 100%;
  background-color: #5e7fdc;
}
.face .eyes {
  width: 128px;
  margin: 0 auto;
  margin-top: 40px;
}
.face .eyes:before {
  content: '';
  display: inline-block;
  width: 30px;
  height: 15px;
  border: 7px solid #383a41;
  margin-right: 20px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom: 0;
}
.face .eyes:after {
  content: '';
  display: inline-block;
  width: 30px;
  height: 15px;
  border: 7px solid #383a41;
  margin-left: 20px;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  border-bottom: 0;
}
.face .dimples {
  width: 180px;
  margin: 0 auto;
  margin-top: 15px;
}
.face .dimples:before {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 80px;
  border-radius: 50%;
  background-color: rgba(235, 109, 109, 0.4);
}
.face .dimples:after {
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 80px;
  border-radius: 50%;
  background-color: rgba(235, 109, 109, 0.4);
}
.face .mouth {
  width: 40px;
  height: 5px;
  border-radius: 5px;
  background-color: #383a41;
  margin: 0 auto;
  margin-top: 25px;
}
